import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';

import { DebounceInput } from 'react-debounce-input'
import Select from 'react-select'
import { Modal } from 'react-bootstrap';

import { GET } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'
import PriceTag60x40 from './PriceTag60x40'
import PriceTag60x30 from './PriceTag60x30'
import PriceTag40x30 from './PriceTag40x30'
import PriceTag30x20 from './PriceTag30x20'
import useDidMountEffect from 'pages/parts/useDidMountEffect';

function PriceTags() {
	const { t } = useTranslation();
	const dispatch = useDispatch()

	const reduxProductList = useSelector(state => state.transferData.documentInProductList)
	//const reduxSettings = useSelector(state => state.settings)

	const printRef = useRef(null);

	// const formates = ['CODE128', 'CODE39', 'EAN13', 'ITF14', 'MSI', 'pharmacode', 'codabar', 'upc']
	const formates = [
		{
			id: 'CODE128',
			name: 'CODE128'
		},
		{
			id: 'CODE39',
			name: 'CODE39'
		},
		{
			id: 'EAN13',
			name: 'EAN13'
		},
	]

	const [settings, setSettings] = useState({
		settingsModal: false,
		activatePrice: 0,
		activeA4: false,
		activeDate: true,
		activeOldPrice: false,
		barcode: false,
		hideOldPrice: false,
		format: 'CODE128',
	})

	const [barcode, setBarcode] = useState('')
	const [poses, setPoses] = useState([])
	const [posId, setPosId] = useState(null)

	// const [activateBankPrice, setActivateBankPrice] = useState(false)
	const [selectedPriceTag, setSelectedPriceTag] = useState({
		'priceTag60x40_1': false,
		'priceTag60x30_1': true,
		'priceTag60x30_2': false,
		'priceTag60x30_3': false,
		'priceTag60x30_4': false,
		'priceTag60x30_5': false,
		'priceTag60x30_6': false,
		'priceTag60x30_7': false,
		'priceTag60x30_8': false,
		'priceTag60x30_9': false,
		'priceTag60x30_10': false,
		'priceTag60x30_11': false,
		'priceTag40x30_1': false,
		'priceTag40x30_2': false,
		'priceTag40x30_3': false,
		'priceTag30x20_1': false,
		'priceTag30x20_2': false,
		'priceTag30x20_3': false,
		'priceTag30x20_4': false,
	})
	const [products, setProducts] = useState([])
	const [printProducts, setPrintProducts] = useState([])
	const [searchData, setSearchData] = useState([])
	const [search, setSearch] = useState('')
	const [data, setData] = useState({
		productName: 'ВАФЛИ СЛАДОНЕЖ С ШОКОЛАДНЫМИ ШОКОЛАДНЫМИ',
		posName: 'Pos market',
		salePrice: '175000',
		actionPrice: '175000',
		quantity: 1,
		currencyId: 1,
		currencyName: "So'm",
		barcode: "6921734977878",
		uomName: "шт",
		productImageUrl: ""
	});
	const [cashbox, setCashbox] = useState({
		cashboxId: 11,
		defaultCurrency: 'So`m',
		hidePriceIn: true,
		loyaltyApi: "",
		posId: 15,
		posName: "mDokon test",
		saleMinus: false,
	})

	async function searchProduct(value) {
		setSearch(value)
		if (value.length > 0) {
			const response = await GET('/services/web/api/product-price-tags-list', { posId: posId, name: value, barcode: barcode, })
			if (response) {
				if (response.length === 0) {
					toast.error(t('nothing_found'))
				}
				if (response.length === 1) {
					addToList(response[0])
					setSearch('')
				}
				if (response.length > 1) {
					setSearchData(response)
				}
			}
		}
	}

	const addToList = (product, fromList = false) => {
		if (fromList) {
			var searchDataCopy = [...searchData]
			var newArray = searchDataCopy.filter(e => e.barcode !== product.barcode)
			setSearchData(newArray)
			if (newArray.length === 0) {
				setSearch('')
			}
		} else {
			setSearch('')
		}
		product.salePriceOriginal = product.salePrice
		if (settings.activeOldPrice) {
			product.actionPrice = product.oldSalePrice
		}
		if (settings.activatePrice === 0) {
			product.salePrice = product.salePriceOriginal
		} else if (settings.activatePrice === 1) {
			product.salePrice = product.wholesalePrice;
		} else if (settings.activatePrice === 2) {
			product.salePrice = product.bankPrice;
		}
		var found = false
		if (products.length) {
			for (let i = 0; i < products.length; i++) {
				if (products[i].barcode === product.barcode) {
					products[i].quantity += 1
					found = true
				}
			}

			if (!found) {
				product.quantity = 1
				setProducts([...products, product]);
			}
		} else {
			product.quantity = 1
			setProducts([...products, product]);
		}
	}

	const selectProduct = (product) => {
		for (let i = 0; i < products.length; i++) {
			if (product.id === products[i]['id']) {
				products[i]['selected'] = true
			} else {
				products[i]['selected'] = false
			}
		}
		setData(product)
	}

	const deleteItem = (index) => {
		var productsCopy = [...products]
		productsCopy.splice(index, 1)
		setProducts(productsCopy)
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current
	});

	const selectPriceTag = (selectedKey) => {
		var selectedPriceTagCopy = { ...selectedPriceTag }
		for (const [key] of Object.entries(selectedPriceTagCopy)) {
			if (key === selectedKey) {
				selectedPriceTagCopy[key] = true
			} else {
				selectedPriceTagCopy[key] = false
			}
		}
		setSelectedPriceTag(selectedPriceTagCopy)
	}

	function handleProductChange(event, i) {
		var productsCopy = [...products]
		productsCopy[i][event.target.name] = event.target.value
		setProducts(productsCopy)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
			setPosId(response[0]['id'])
			if (response[0]['currency'] === 1) {
				setCashbox({ ...cashbox, 'defaultCurrency': 'So`m' })
			}
			if (response[0]['currency'] === 2) {
				setCashbox({ ...cashbox, 'defaultCurrency': 'USD' })
			}
		}
	}

	useEffect(() => {
		if (reduxProductList.length > 0) {
			console.log(reduxProductList);
			setProducts(reduxProductList)
			dispatch({ type: 'SET_DOCUMENT_IN_PRODUCT_LIST', payload: [] })
		}
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		var productsCopy = [...products]
		var prepareProducts = []
		for (let i = 0; i < productsCopy.length; i++) {
			for (let j = 0; j < productsCopy[i]['quantity']; j++) {
				prepareProducts.push(productsCopy[i])
			}
		}
		setPrintProducts(prepareProducts)
	}, [products])

	useEffect(() => {
		if (search.length === 0) {
			setSearchData([])
		}
	}, [search])

	useDidMountEffect(() => {
		var productsCopy = [...products]
		for (let i = 0; i < productsCopy.length; i++) {
			if (settings.activeOldPrice) {
				productsCopy[i].actionPrice = productsCopy[i].oldSalePrice
			} else {
				productsCopy[i].actionPrice = '';
			}
		}
		setProducts(productsCopy)
	}, [settings.activeOldPrice]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		var productsCopy = [...products]
		for (let i = 0; i < productsCopy.length; i++) {
			if (settings.activatePrice === 0) {
				productsCopy[i].salePrice = productsCopy[i].salePriceOriginal
			} else if (settings.activatePrice === 1) {
				productsCopy[i].salePrice = productsCopy[i].wholesalePrice;
			} else if (settings.activatePrice === 2) {
				productsCopy[i].salePrice = productsCopy[i].bankPrice;
			}
		}
		setProducts(productsCopy)
	}, [settings.activatePrice]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card mb-2">
				<div className="card-body">
					<div className="row mb-2">
						<div className="col-md-5 vertical-center">
							<div className="d-flex position-relative">
								<div className="position-relative w-100">
									{searchData.length > 0 ?
										<i className="uil uil-times input-search-icon cursor"
											onClick={() => setSearch('')}></i>
										:
										<i className={'uil uil-qrcode-scan search-barcode ' + (barcode && 'text-primary')} onClick={() => setBarcode(!barcode)}></i>
									}
									<DebounceInput
										value={search}
										type="text"
										className="form-control"
										placeholder={t('SEARCH') + '...'}
										minLength={1}
										debounceTimeout={1000}
										onChange={(e) => searchProduct(e.target.value)}
									/>
								</div>

								{searchData.length > 0 &&
									<span className="dropdown-search-menu">
										<div className="dropdown-menu-list">
											<div className="table-responsive p-2 mb-0">
												<table className="table">
													<thead>
														<tr>
															<th>{t('NAME_OF_PRODUCT')}</th>
															<th className="text-center">{t('BARCODE')}</th>
															<th className="text-center">{t('ACTION')}</th>
														</tr>
													</thead>
													<tbody>
														{searchData.map((item, index) => (
															<tr key={index}>
																<td>{item.productName}</td>
																<td className="text-center">{item.barcode}</td>
																<td>
																	<div className="table-actions">
																		<div className="table-action-button table-action-primary-button" onClick={() => addToList(item, true)}>
																			<i className="uil uil-plus"></i>
																		</div>
																	</div>
																</td>
															</tr>
														))
														}
													</tbody>
												</table>
											</div>
										</div>
									</span>
								}
							</div>
						</div>
						<div className="col-md-5 vertical-center">
							<Select
								options={poses}
								value={poses.find(option => option.id === posId)}
								onChange={(option) => {
									setPosId(option.id)
									if (option.currency === 1) {
										setCashbox({ ...cashbox, 'defaultCurrency': 'So`m' })
									}
									if (option.currency === 2) {
										setCashbox({ ...cashbox, 'defaultCurrency': 'USD' })
									}
								}
								}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="col-md-2 vertical-center">
							<button className="btn btn-outline-primary" onClick={() => setSettings({ ...settings, settingsModal: true })}>
								{t('SETTINGS')}
							</button>
						</div>
					</div>

					{/* TABLE */}
					<div className="table-responsive h-table-price-tags">
						<table className="table fz14">
							<thead>
								<tr>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('QUANTITY')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('PRICE')}</th>
									<th className="text-center">{settings.activeOldPrice ? t('OLD_PRICE') : t('DISCOUNT')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{products.map((product, index) => (
									<tr key={index} className={"cursor " + (product.selected ? "cashbox-table-active" : "cashbox-table-bg-on-hover")}>
										<td onClick={() => selectProduct(product)}>{index + 1}. {product.productName}</td>
										<td className="text-center">
											<div className="d-flex justify-content-center">
												<input type="number" className="auto-width-input"
													name="quantity"
													value={product.quantity}
													onChange={(e) => handleProductChange(e, index)} />
											</div>
										</td>
										<td className="text-center" onClick={() => selectProduct(product)}>{product.barcode}</td>
										<td className="text-center" onClick={() => selectProduct(product)}>
											{settings.activatePrice === 0 && formatMoney(product.salePrice)}
											{settings.activatePrice === 1 && formatMoney(product.wholesalePrice)}
											{settings.activatePrice === 2 && formatMoney(product.bankPrice)}
										</td>
										<td className="text-center">
											<div className="d-flex justify-content-center">
												<input type="number" className="auto-width-input"
													name="actionPrice"
													value={product.actionPrice}
													onChange={(e) => handleProductChange(e, index)} />
											</div>
										</td>
										<td>
											<div className="table-actions">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteItem(index)}>
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					{/* TABLE */}
				</div>
			</div>

			{/* PRICE TAG EXAMPLES */}
			<div className="card h-table-price-tags2 position-relative">
				<div className="card-body">
					<div className={"h-table-price-tags3 " + (settings.activeDate ? '' : 'hide-date')}>
						<h6 className="mb-2">{t('price_tag')} (60х40 мм)</h6>
						<PriceTag60x40
							cashbox={cashbox}
							data={data}
							selectedPriceTag={selectedPriceTag}
							selectPriceTag={selectPriceTag}
							printProducts={printProducts}
							printRef={printRef}
							settings={settings}
						>
						</PriceTag60x40>
						<h6 className="mb-2">{t('price_tag')} (60х30 мм)</h6>
						<PriceTag60x30
							cashbox={cashbox}
							data={data}
							selectedPriceTag={selectedPriceTag}
							selectPriceTag={selectPriceTag}
							printProducts={printProducts}
							printRef={printRef}
							settings={settings}
						>
						</PriceTag60x30>
						<h6 className="mb-2">{t('price_tag')} (40x30 мм)</h6>
						<PriceTag40x30
							cashbox={cashbox}
							data={data}
							selectedPriceTag={selectedPriceTag}
							selectPriceTag={selectPriceTag}
							printProducts={printProducts}
							printRef={printRef}
							settings={settings}
						>
						</PriceTag40x30>
						<h6 className="mb-2">{t('price_tag')} (30x20 мм)</h6>
						<PriceTag30x20
							cashbox={cashbox}
							data={data}
							selectedPriceTag={selectedPriceTag}
							selectPriceTag={selectPriceTag}
							printProducts={printProducts}
							printRef={printRef}
							settings={settings}
						>
						</PriceTag30x20>
					</div>
					<button type="button" className="btn btn-outline-primary w-25 absolute printing me-2" disabled={!products.length} onClick={handlePrint}>
						{t('print')}
					</button>
				</div>
			</div>

			{/* MODAL SETTINGS */}
			<Modal show={settings.settingsModal} animation={false} size="sm" centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setSettings({ ...settings, settingsModal: false })}>
				<Modal.Header closeButton>
					<Modal.Title>{t('SETTINGS')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-between mb-2">
						<p className="fw-600 vertical-center">{t('WHOLESALE_PRICE')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" checked={settings.activatePrice === 1}
								onChange={(e) => setSettings({ ...settings, activatePrice: e.target.checked ? 1 : 0 })} />
						</div>
					</div>
					<div className="d-between mb-2">
						<p className="fw-600 vertical-center">{t('BANK_PRICE')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" checked={settings.activatePrice === 2}
								onChange={(e) => setSettings({ ...settings, activatePrice: e.target.checked ? 2 : 0 })} />
						</div>
					</div>
					<div className="d-between mb-2">
						<p className="fw-600 vertical-center">{t('A4 бумага')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" value={settings.activeA4}
								onChange={(e) => setSettings({ ...settings, activeA4: e.target.checked })} />
						</div>
					</div>
					<div className="d-between mb-2">
						<p className="fw-600 vertical-center">{t('DATE')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" checked={settings.activeDate}
								onChange={(e) => setSettings({ ...settings, activeDate: e.target.checked })} />
						</div>
					</div>
					<div className="d-between mb-2">
						<p className="fw-600 vertical-center">{t('OLD_PRICE')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" checked={settings.activeOldPrice}
								onChange={(e) => setSettings({ ...settings, activeOldPrice: e.target.checked })} />
						</div>
					</div>
					<div className="d-between mb-2">
						<p className="fw-600 vertical-center">{t('HIDE_OLD_PRICE')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" checked={settings.hideOldPrice}
								onChange={(e) => setSettings({ ...settings, hideOldPrice: e.target.checked })} />
						</div>
					</div>
					<div className="mb-2">
						<p className="fw-600 vertical-center mb-2">{t('BARCODE_FORMAT')}</p>
						<Select
							options={formates}
							value={formates.find(option => option.id === settings.format) || ''}
							onChange={(option) => setSettings({ ...settings, 'format': option.id })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL SETTINGS */}
		</>
	)
}

export default PriceTags
