import React, { useEffect, useState, useRef } from "react"
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { DebounceInput } from 'react-debounce-input'
import { useTranslation } from 'react-i18next'
import DatePicker from "react-datepicker"
import Select, { components } from 'react-select'
import { Modal, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useReactToPrint } from 'react-to-print';
import XLSX from 'xlsx';

import { useForm, Controller } from "react-hook-form"

import Pagination from 'pages/parts/Pagination'
import TableControl from 'pages/parts/TableControl'
import PriceTag60x40 from '../priceTags/PriceTag60x40'
import PriceTag60x30 from '../priceTags/PriceTag60x30'
import PriceTag40x30 from '../priceTags/PriceTag40x30'
import PriceTag30x20 from '../priceTags/PriceTag30x20'

import { GET, PGET, POST, PUT, FILE } from 'helpers/api'
import { formatMoney, formatDateBackend, findFromArrayById, findIdFromArray, checkRole, returnMinDate } from "helpers/helpers";
import ConfirmModal from "pages/parts/ConfirmModal"

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const printRef = useRef(null);

	const reduxSettings = useSelector(state => state.settings)
	const reduxTransferData = useSelector(state => state.transferData)
	// console.log(reduxSettings);

	const canCreateProduct = checkRole('MAIN_PRODUCTS_ADD')

	const searchRef = useRef(null);

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		defaultValues: {
			name: '',
			barcode: '',
			uomId: 1,
			categoryId: 2,
			modQuantity: ''
		}
	});
	const { errors } = formState

	const {
		register: register2,
		formState: { errors: errors2 },
		handleSubmit: handleSubmit2,
		reset: reset2,
	} = useForm({
		mode: "onChange",
		defaultValues: {
			name: '',
			phone: '',
		}
	});

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const paymentTypes = [{ 'id': 1, 'name': t('SAFE') }, { 'id': 2, 'name': t('BANK') }]
	const seasons = [{ id: 0, name: t('EMPTY') }, { id: 1, name: t('SEASONAL') }, { id: 2, name: t('NOT_SEASONAL') }];
	// const paids = [{ id: 1, name: t('NOT_PAID') }, { id: 2, name: t('PAID') }];

	const [pageCount, setPageCount] = useState(0)
	const [searchDataExist, setSearchDataExist] = useState(0)

	const [uoms, setUoms] = useState([]) // for instant create modal
	const [categories, setCategories] = useState([]) // for instant create modal
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [poses, setPoses] = useState([])
	const [searchData, setSearchData] = useState([])
	const [printProducts, setPrintProducts] = useState([])
	const [wallets, setWallets] = useState([])
	const [banks, setBanks] = useState([])

	const [barcode, setBarcode] = useState(false)
	const [modalSerialNumbers, setModalSerialNumbers] = useState(false)
	const [modalProduct, setModalProduct] = useState(false)
	const [modalProductData, setModalProductData] = useState(false)
	const [modalUpdateProduct, setModalUpdateProduct] = useState(false)
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalDeleteAllConfirm, setModalDeleteAllConfirm] = useState(false)
	const [modalOrganization, setModalOrganization] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)
	const [modalPriceTag, setModalPriceTag] = useState(false)
	const [allSelected, setAllSelected] = useState(false)
	const [expenseSetted, setExpenseSetted] = useState(false)

	const [search, setSearch] = useState('')
	const [serialNumber, setSerialNumber] = useState('')

	const [totalAmount, setTotalAmount] = useState({ totalIncome: 0, totalSale: 0, totalQuantity: 0 })
	const [modalData, setModalData] = useState({})
	const [selectedItem, setSelectedItem] = useState({})
	const [data, setData] = useState({
		productList: [],
		posId: null,
		productCategoryId: null,
		paymentTypeId: 1,
		walletId: '',
		bankId: '',
		paid: 1,
		based: null,
		organizationId: null,
		currencyId: 1,
		currencyName: "",
		productSerial: false,
		importExcel: false,
		wholesalePriceMarkup: 0,
		bankPriceMarkup: 0,
		salePriceMarkup: 0,
		defaultVat: 0,
		totalAmount: 0,
		expense: '',
		error: false,
	})
	const [cashbox] = useState({
		cashboxId: 11,
		defaultCurrency: 'So`m',
		hidePriceIn: true,
		loyaltyApi: "",
		posId: 15,
		posName: "mDokon test",
		saleMinus: false,
	})
	const [selectedPriceTag, setSelectedPriceTag] = useState({
		'priceTag60x40_1': false,
		'priceTag60x30_1': false,
		'priceTag60x30_2': false,
		'priceTag60x30_3': false,
		'priceTag60x30_4': false,
		'priceTag60x30_5': false,
		'priceTag60x30_6': false,
		'priceTag60x30_7': false,
		'priceTag60x30_8': false,
		'priceTag60x30_9': false,
		'priceTag40x30_1': false,
		'priceTag40x30_2': false,
		'priceTag40x30_3': false,
		'priceTag30x20_1': false,
		'priceTag30x20_2': false,
		'priceTag30x20_3': false,
		'priceTag30x20_4': false,
	})

	async function searchOfdProduct() {
		const response = await GET('/services/web/api/get-ofd-product?barcode=' + getValues('barcode'), {}, { loader: true })
		if (response && response.gtin) {
			setValue('name', response.name)
			setValue('gtin', response.gtin)
		} else {
			return toast.error('ИКПУ ' + t('NOT_FOUND'))
		}
	}

	function onChangePrice() {
		var dataCopy = { ...data }
		if (Number(dataCopy.expense)) {
			if (!expenseSetted) {
				for (let i = 0; i < dataCopy?.productList?.length; i++) {
					var percent = (Number(dataCopy.productList[i].originalPrice) * 100) / Number(totalAmount.totalIncome);
					if (!reduxSettings.documentsInCalculationByAmount) {
						dataCopy.productList[i].price = Number(dataCopy.productList[i].originalPrice + (dataCopy.expense * (percent / 100))).toFixed(2)
					}
				}
				setExpenseSetted(true)
				setData(dataCopy)
				countTotalAmount(undefined, false, dataCopy)
			}
		} else {
			for (let i = 0; i < dataCopy?.productList?.length; i++) {
				if (!reduxSettings.documentsInCalculationByAmount) {
					dataCopy.productList[i].price = dataCopy.productList[i].originalPrice
				}
			}
			setData(dataCopy)
			setExpenseSetted(false)
			countTotalAmount(undefined, false, dataCopy)
		}
	}

	const selectPriceTag = (selectedKey) => {
		var selectedPriceTagCopy = { ...selectedPriceTag }
		for (const [key] of Object.entries(selectedPriceTagCopy)) {
			if (key === selectedKey) {
				selectedPriceTagCopy[key] = true
			} else {
				selectedPriceTagCopy[key] = false
			}
		}
		setSelectedPriceTag(selectedPriceTagCopy)
	}

	function openPriceTagModal() {
		var dataCopy = { ...data }
		for (let i = 0; i < dataCopy.productList.length; i++) {
			dataCopy.productList[i]['printQuantity'] = dataCopy.productList[i]['quantity']
		}
		setData(dataCopy)
		setModalPriceTag(true)
	}

	function prevHandlePrint() {
		var copyArr = [...data.productList]
		var prepareProducts = []
		for (let i = 0; i < copyArr.length; i++) {
			if (data.productList[i].checked) {
				copyArr[i].productName = copyArr[i].name
				copyArr[i].posName = cashbox.posName
				copyArr[i].productName = copyArr[i].name
				for (let j = 0; j < copyArr[i]['printQuantity']; j++) {
					prepareProducts.push(copyArr[i])
				}
			}
		}
		setPrintProducts(prepareProducts)
		setTimeout(() => {
			handlePrint()
		}, 300)
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current
	});


	async function createUpdate(e, draft = false) {
		e.preventDefault();
		var sendData = { ...data }
		sendData.totalAmount = totalAmount.totalIncome
		sendData.productSerial = false
		for (let i = 0; i < sendData.productList.length; i++) {
			sendData.productList[i]['totalAmount'] = sendData.productList[i]['price'] * sendData.productList[i]['quantity']
			if (sendData.productList[i]['serialNumbers']?.length > 0) {
				sendData.productSerial = true
			}

			if (!sendData.productList[i]['quantity']) {
				sendData.error = true
			}
			if (Number(sendData.productList[i]['price']) > 1000000000 || Number(sendData.productList[i]['salePrice']) > 1000000000 ||
				Number(sendData.productList[i]['wholesalePrice']) > 1000000000 || Number(sendData.productList[i]['bankPrice']) > 1000000000) {
				sendData.error = true
			}
			if (sendData.productList[i]['price'] === "" || sendData.productList[i]['price'] < 0.0001) {
				sendData.error = true
			}
			if (sendData.productList[i]['wholesalePrice'] === "" || (Number(sendData.productList[i]['wholesalePrice']) < Number(sendData.productList[i]['price']))) {
				sendData.error = true
			}
			if (sendData.productList[i]['wholesalePrice'] === "" || (Number(sendData.productList[i]['salePrice']) < Number(sendData.productList[i]['wholesalePrice']))) {
				sendData.error = true
			}
			if ((!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.bankPrice)) {
				if (sendData.productList[i]['bankPrice'] === "" || (Number(sendData.productList[i]['bankPrice']) < Number(sendData.productList[i]['price']))) {
					sendData.error = true
				}
			}
			if (sendData.productList[i]['vat'] === "") {
				sendData.error = true
			}
		}

		if (sendData.error) {
			toast.error(t('ERROR') + ': ' + t('CHECK_FILLED_INPUTS'))
			return;
		}

		var response;
		if (draft) {
			if (id) {
				response = await PUT('/services/web/api/documents-in-draft', sendData, { loader: true })
			} else {
				response = await POST('/services/web/api/documents-in-draft', sendData, { loader: true })
			}
		} else {
			response = await POST('/services/web/api/documents-in', sendData, { loader: true })
		}

		if (response) {
			dispatch({ type: 'SET_DOCUMENT_IN_CREATE_DATA', payload: {} })
			navigate('/documents-in')
		}
	}

	async function searchProduct(value) {
		setSearchDataExist(0)
		setSearch(value)
		if (value.length === 0) {
			setSearchData([])
			setSearchDataExist(0)
		} else {
			const response = await PGET(
				"/services/web/api/product-in-helper",
				{ 'name': value, 'posId': data.posId, 'barcode': barcode, 'currencyId': data.currencyId },
				{ loader: true }
			)
			setPageCount(Math.ceil(response.headers['x-total-count'] / 20))

			var dataCopy = { ...data }
			var responseCopy = [...response.data]
			var responseCopy2 = [...response.data]
			for (let i = 0; i < responseCopy.length; i++) {
				for (let j = 0; j < dataCopy.productList.length; j++) {
					if (
						responseCopy[i]['productId'] ===
						dataCopy.productList[j]['productId']
					) {
						if (responseCopy.length === 1) {
							setSearchDataExist(2)
							setSearch('')
							return;
						}
						responseCopy2 = responseCopy2.filter(e => e.productId !== dataCopy.productList[j]['productId'])
					}
				}
			}
			if (responseCopy2.length === 0) {
				setSearchData([])
				setSearchDataExist(1)
				return
			}

			if (/^\d+$/.test(value) && responseCopy2.length === 1) {
				setSearchData(responseCopy2)
				if (responseCopy2[0]['productShow']) {
					addToList(0, responseCopy2, true)
					setSearch('')
				}
			} else {
				setSearchData(responseCopy2)
			}
		}
	}

	function handleExpDateChange(date, index) {
		var dataCopy = { ...data }
		dataCopy.productList[index]['expDate'] = formatDateBackend(date)
		setData(dataCopy)
	}

	function handleProductListChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.productList[index][e.target.name] = e.target.value.replace(/,/g, '.').replace(/[^0-9.]/g, '')

		if (e.target.name === 'vat') {
			if (dataCopy.productList[index][e.target.name] > 12) {
				dataCopy.productList[index][e.target.name] = 0
			}
		}

		if (e.target.name === 'price') {
			dataCopy.productList[index].originalPrice = Number(e.target.value)
		}

		if (reduxSettings.documentsInCalculationByAmount) {
			if (e.target.name === 'totalPrice' || e.target.name === 'quantity') {
				dataCopy.productList[index].price = Number(dataCopy.productList[index].totalPrice) / (dataCopy.productList[index].quantity ?? 0)
				dataCopy.productList[index].salePrice = Number(dataCopy.productList[index].totalPrice) / (dataCopy.productList[index].quantity ?? 0)
				dataCopy.productList[index].bankPrice = Number(dataCopy.productList[index].totalPrice) / (dataCopy.productList[index].quantity ?? 0)
				dataCopy.productList[index].wholesalePrice = Number(dataCopy.productList[index].totalPrice) / (dataCopy.productList[index].quantity ?? 0)
			}
		}

		setData(dataCopy)

		if (e.target.name === 'quantity') {
			countTotalAmount(index, false)
		}
		if (e.target.name === 'price') {
			countTotalAmount(index)
		}
		if (e.target.name === 'salePrice') {
			countTotalAmount(index, false)
		}
		if (e.target.name === 'totalPrice') {
			countTotalAmount(index, false)
		}
	}

	function countTotalAmount(index, callSetPrice = true, newData) {
		if (index !== undefined && callSetPrice) setPrices(index)

		var dataCopy = { ...data }
		if (newData) {
			dataCopy = newData
		}
		var temporaryTotalQuantity = 0
		var temporaryTotalIncome = 0
		var temporaryTotalSale = 0

		for (let i = 0; i < dataCopy.productList?.length; i++) {
			if (dataCopy.productList[i]['price'] && dataCopy.productList[i]['quantity']) {
				temporaryTotalQuantity += Number(dataCopy.productList[i]['quantity'])
				temporaryTotalIncome += dataCopy.productList[i]['price'] * dataCopy.productList[i]['quantity']
				temporaryTotalSale += dataCopy.productList[i]['salePrice'] * dataCopy.productList[i]['quantity']
			}
		}

		setTotalAmount({ totalIncome: temporaryTotalIncome, totalSale: temporaryTotalSale, totalQuantity: temporaryTotalQuantity })
	}

	function setPrices(index) {
		var dataCopy = { ...data }
		if (dataCopy.productList[index]['price'] !== "") {
			dataCopy.productList[index]['wholesalePrice'] = Number(dataCopy.productList[index]['price']) + Number((dataCopy.productList[index]['price'] * (dataCopy.wholesalePriceMarkup / 100)))
			dataCopy.productList[index]['bankPrice'] = Number(dataCopy.productList[index]['price']) + Number((dataCopy.productList[index]['price'] * (dataCopy.bankPriceMarkup / 100)))
			dataCopy.productList[index]['salePrice'] = Number(dataCopy.productList[index]['price']) + Number((dataCopy.productList[index]['price'] * (dataCopy.salePriceMarkup / 100)))
		} else {
			dataCopy.productList[index]['wholesalePrice'] = ""
			dataCopy.productList[index]['bankPrice'] = ""
			dataCopy.productList[index]['salePrice'] = ""
		}
		setData(dataCopy)
	}

	function handleAllSelected() {
		var dataCopy = { ...data }
		if (dataCopy.productList) {
			for (let i = 0; i < dataCopy.productList.length; i++) {
				if (allSelected) {
					dataCopy.productList[i].checked = false;
				} else {
					dataCopy.productList[i].checked = true;
				}
			}
		}
		if (allSelected) {
			setPrintProducts([])
		} else {
			setPrintProducts(dataCopy.productList)
		}
		setData({ ...data, dataCopy })
		setAllSelected(!allSelected)
	}

	function handleChange(index) {
		var dataCopy = { ...data }
		var printProductsCopy = [...printProducts]
		dataCopy.productList[index].checked = !dataCopy.productList[index].checked
		dataCopy.productList[index].productName = dataCopy.productList[index].name
		if (dataCopy.productList[index].checked) {
			printProductsCopy.push(dataCopy.productList[index])
		} else {
			if (dataCopy.productList.length === 1) {
				setModalPriceTag(false)
				setPrintProducts([])
				return;
			}
			var inProductIndex = printProductsCopy.findIndex(item => item.productId === dataCopy.productList[index].productId)
			if (index >= 0) {
				printProductsCopy.splice(inProductIndex, 1)
			}

		}
		setPrintProducts(printProductsCopy)
		setData(dataCopy)
	}

	function addToList(index, arr = [], putCursor = false) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchData]
		}

		if (!searchDataCopy[index].price) {
			searchDataCopy[index].price = ''
		}
		if (!searchDataCopy[index].wholesalePrice) {
			searchDataCopy[index].wholesalePrice = ''
		}
		if (!searchDataCopy[index].bankPrice) {
			searchDataCopy[index].bankPrice = ''
		}
		if (!searchDataCopy[index].salePrice) {
			searchDataCopy[index].salePrice = ''
		}
		if (data.defaultVat) {
			searchDataCopy[index].vat = data.defaultVat
		} else {
			searchDataCopy[index].vat = 0
		}
		if (searchDataCopy[index].originalPrice) {
			searchDataCopy[index].price = searchDataCopy[index].originalPrice
		}

		searchDataCopy[index].quantity = ''
		searchDataCopy[index].label = ''
		searchDataCopy[index].originalPrice = searchDataCopy[index].price
		searchDataCopy[index].originalSalePrice = searchDataCopy[index].salePrice
		searchDataCopy[index].originalWholesalePrice = searchDataCopy[index].wholesalePrice
		searchDataCopy[index].originalBankPrice = searchDataCopy[index].bankPrice
		searchDataCopy[index].checked = false
		searchDataCopy[index].serialNumbers = []

		var dataCopy = { ...data }
		dataCopy.productList.push(searchDataCopy[index])
		onChangePrice(data?.expense)
		setData(dataCopy)

		if (index === 0 && putCursor) { // put cursor automatically
			setTimeout(() => {
				const byId = document.getElementById(dataCopy.productList.length)
				byId?.select()
			}, 200);
		}

		searchDataCopy.splice(index, 1)
		setSearchData(searchDataCopy)

		if (searchDataCopy.length === 0) {
			setSearch('')
		}
		searchRef.current.focus()
	}

	function deleteAllProduct() {
		var dataCopy = { ...data }
		if (printProducts.length > 0) {
			setPrintProducts([])
		}
		dataCopy.productList = []

		countTotalAmount(undefined, false)

		dataCopy.importExcel = false

		setData(dataCopy)
		dispatch({ type: 'SET_DOCUMENT_IN_CREATE_DATA', payload: {} })
		setModalDeleteAllConfirm(false)
	}

	function deleteProduct(index) {
		var dataCopy = { ...data }
		if (printProducts.length > 0) {
			var copyPrintProducts = [...printProducts]
			var printIndex = copyPrintProducts.findIndex(item => item.productId === dataCopy.productList[index].productId)
			if (printIndex >= 0) {
				copyPrintProducts.splice(printIndex, 1)
			}
			setPrintProducts(copyPrintProducts)
		}
		dataCopy.productList.splice(index, 1)


		countTotalAmount(undefined, false)

		if (dataCopy.productList.length === 0) {
			dataCopy.importExcel = false
		}
		setData(dataCopy)
	}

	function serialProductsModal(item) {
		setModalData(JSON.parse(JSON.stringify(item)))
		setModalSerialNumbers(true)
	}

	function addSerialNumber() {
		var modalDataCopy = { ...modalData }
		var unitFound = modalDataCopy.serialNumbers.find(x => x === serialNumber)

		if (!unitFound) {
			modalDataCopy.serialNumbers.push(serialNumber)
			setModalData(modalDataCopy)
			setSerialNumber('')
		}
	}

	function deleteSerialNumber(index) {
		var modalDataCopy = { ...modalData }
		modalDataCopy.serialNumbers.splice(index, 1)
		setModalData(modalDataCopy)
	}

	function saveItemSerial() {
		var dataCopy = { ...data }
		for (let i = 0; i < dataCopy.productList.length; i++) {
			if (modalData.productId === dataCopy.productList[i]['productId']) {
				dataCopy.productList[i]['serialNumbers'] = modalData.serialNumbers
				dataCopy.productList[i]['quantity'] = modalData.serialNumbers.length > 0 ? modalData.serialNumbers.length : ""
			}
		}
		setData(dataCopy)
		countTotalAmount()
		setModalSerialNumbers(false)
	}

	function showConfirmModal() {
		setModalConfirm(true)
		getWallets()
		getBanks()
	}

	async function showProductCreateModal(bool) {
		if (!bool) {
			reset()
		}
		if (uoms.length === 0) {
			const response = await GET('/services/web/api/product-uom-helper')
			setUoms(response)
		}
		if (search.length > 0) {
			setValue('barcode', search)
		} else {
			setValue('barcode', '')
		}
		setModalProduct(bool)
	}

	async function showProductUpdateModal(bool, index) {
		if (bool) {
			var response = await GET(`/services/web/api/product/${data.productList[index]['productId']}`, selectedItem, { loader: true })
			response.index = index
			setSelectedItem(response)
		} else {
			setSelectedItem({})
		}
		setModalUpdateProduct(bool)
	}

	async function changeProduct(e) {
		e.preventDefault()

		var dataCopy = { ...data }
		await PUT('/services/web/api/product', selectedItem, { loader: true })
		dataCopy.productList[selectedItem.index]['name'] = selectedItem.name
		setData(dataCopy)
		showProductUpdateModal(false)
	}

	function showOrganizationCreateModal(bool) {
		if (!bool) {
			reset2()
		}

		setModalOrganization(bool)
	}

	function handleSelectOrganization(option) {
		if (option.id === -1) {
			showOrganizationCreateModal(true)
		} else {
			setData({ ...data, 'organizationId': option.id, 'organizationDate': option.returnDay })
		}
	}

	function handleChangeProductData(e) {
		var documentInCreateProductCopy = { ...reduxTransferData['documentInCreateProduct'] }
		documentInCreateProductCopy[e.target.name] = e.target.checked
		dispatch({ type: 'SET_DOCUMENT_IN_CREATE_PRODUCT', payload: documentInCreateProductCopy })
	}

	function showProductDataModal() {
		setModalProductData(true);
		showProductCreateModal(false);
	}

	function closeProductDataModal() {
		setModalProductData(false);
		showProductCreateModal(true);
	}

	async function createProduct(validatedData) {
		if (reduxSettings.ofd) {
			if (validatedData.gtin.length === 0) {
				return toast.error('ИКПУ ' + t('required'))
			}
			if (validatedData.gtin.split('').every(char => char === validatedData.gtin[0])) {
				return toast.error('ИКПУ ' + t('ERROR'))
			}
		}
		const response = await POST('/services/web/api/product-instant-create', validatedData, { loader: true })
		var product = response.data
		if (response) {
			product.price = ''
			product.wholesalePrice = ''
			product.bankPrice = ''
			product.salePrice = ''
			product.quantity = ''
			product.label = ''
			product.serialNumbers = []
			if (data.defaultVat) {
				product.vat = data.defaultVat
			} else {
				product.vat = 0
			}

			var dataCopy = { ...data }
			var exist = false
			for (let i = 0; i < dataCopy.productList.length; i++) {
				if (
					product.productId ===
					dataCopy.productList[i]['productId']
				) {
					exist = true
				}
			}

			if (!exist) {
				dataCopy.productList.push(product)
				setData(dataCopy)
				setTimeout(() => {
					const byId = document.getElementById(dataCopy.productList.length)
					byId?.select()
				}, 200);
			} else {
				toast.error(t('ERROR') + ': ' + t('PRODUCT_IN_LIST'))
			}

			showProductCreateModal(false)
		}
	}

	async function createOrganization(validatedData) {
		validatedData.phone = '998' + validatedData.phone
		const response = await POST('/services/web/api/organization-instant-create', validatedData, { loader: true })
		if (response) {
			var organizationsCopy = [...organizations]
			organizationsCopy.push(response.data)

			setOrganizations(organizationsCopy)
			setData({ ...data, 'organizationId': response.data.id })
			showOrganizationCreateModal(false)
		}
	}

	async function importFromExel(e) {
		if (
			!data.posId ||
			!data.paymentTypeId ||
			!data.organizationId ||
			!data.currencyId
		) {
			return;
		}
		var formData = new FormData();
		formData.append('file', e.target.files[0])
		formData.append('posId', data.posId)
		formData.append('paymentTypeId', data.paymentTypeId)
		formData.append('organizationId', data.organizationId)
		formData.append('currencyId', data.currencyId)
		formData.append('productSerial', data.productSerial)
		formData.append('based', data.based)
		for (let i = 0; i < organizations.length; i++) {
			if (organizations[i]['id'] === data.organizationId) {
				formData.append('organizationName', organizations[i]['name'])
			}
		}
		if (formData !== undefined) {
			const response = await FILE("services/web/api/document-in-import", formData, { loader: true })
			if (response) {
				navigate('/documents-in/preview/' + response.id)
			}
		}
		e.target.value = ""
	}

	function saveToExcel(key) {
		if (Number(key) === 3) {
			var excelHeaders = [[t('NAME_OF_PRODUCT'), t('BARCODE'), t('UNIT'), t('QUANTITY'), t('PRICE'), t('WHOLESALE_PRICE'), t('SALE_PRICE'), t('DATE'), t('VAT')]]
			for (let i = 0; i < data.productList.length; i++) {
				var excelItems = []
				excelItems.push(data.productList[i]['name'])
				excelItems.push(data.productList[i]['barcode'])
				excelItems.push(data.productList[i]['uomId'])
				excelItems.push(data.productList[i]['quantity'])
				excelItems.push(data.productList[i]['price'])
				excelItems.push(data.productList[i]['wholesalePrice'])
				excelItems.push(data.productList[i]['salePrice'])
				excelItems.push(data.productList[i]['expDate'])
				excelItems.push(data.productList[i]['vat'])
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('GOOD_RECEPTION') + ".xlsx");
		}
	}

	async function paginate(pageNumber) {
		const response = await PGET('/services/web/api/product-in-helper',
			{ 'name': search, 'posId': data.posId, 'barcode': barcode, 'currencyId': data.currencyId, page: pageNumber.selected, size: 20 }
		)
		setSearchData(response.data);
	}

	function generateRandomBarcode() {
		setValue('barcode', Math.floor(Math.random() * 899999 + 100000))
	}

	function setReduxProducts(data) {
		var dataCopy = { ...data }
		if (id) {

		} else if (reduxTransferData.documentInCreateData?.productList && reduxTransferData.documentInCreateData?.productList?.length) {
			dataCopy = reduxTransferData.documentInCreateData
			dataCopy.productList = reduxTransferData.documentInCreateData.productList
			var arr = []
			for (let i = 0; i < dataCopy.productList?.length; i++) {
				dataCopy.productList[i].price = dataCopy.productList[i].originalPrice
				if (dataCopy.productList[i].checked) {
					arr.push(dataCopy.productList[i])
				}
			}
			setPrintProducts(arr)
			var index = arr.length - 1
			if (index >= 0) {
				dataCopy.productName = arr[index].name
				dataCopy.barcode = arr[index].barcode
				dataCopy.price = arr[index].price
				dataCopy.salePrice = arr[index].salePrice
				dataCopy.wholesalePrice = arr[index].wholesalePrice
				dataCopy.bankPrice = arr[index].bankPrice
				dataCopy.posName = cashbox.posName
			}
		}
		countTotalAmount(undefined, false, dataCopy)
		return dataCopy
	}

	async function getPoses() {
		const temporaryPoses = await GET('/services/web/api/pos-helper')
		if (temporaryPoses) {
			setPoses(temporaryPoses)
		}

		const temporaryOrganizations = await GET('/services/web/api/organization-helper')
		if (temporaryOrganizations) {
			var defaultOrganizations = [{ 'id': -1, 'name': t('ADD_NEW') }]
			temporaryOrganizations.forEach(element => {
				defaultOrganizations.push(element)
			});
			setOrganizations(defaultOrganizations)
		}
		if (id) {
			return;
		}
		var dataCopy = { ...data }
		if (temporaryOrganizations.length > 0 && temporaryPoses.length > 0) {
			if (
				reduxSettings.posId &&
				reduxSettings.organizationId &&
				findIdFromArray(temporaryPoses, reduxSettings.posId) &&
				findIdFromArray(temporaryOrganizations, reduxSettings.organizationId)
			) {
				dataCopy.posId = reduxSettings.posId
				dataCopy.organizationId = reduxSettings.organizationId
				dataCopy.currencyId = findIdFromArray(currencies, reduxSettings.currencyId) ? findIdFromArray(currencies, reduxSettings.currencyId) : 1

				dataCopy = setReduxProducts(dataCopy)
				setData(dataCopy)
				return;
			}

			dataCopy.posId = temporaryPoses[0]['id']
			dataCopy.organizationId = temporaryOrganizations[0]['id']
			dataCopy = setReduxProducts(dataCopy)
			setData(dataCopy)
		} else if (temporaryOrganizations.length > 0) {
			dataCopy.organizationId = temporaryOrganizations[0]['id']
			dataCopy = setReduxProducts(dataCopy)
			setData(dataCopy)
		}
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function getWallets() {
		const response = await GET(`/services/web/api/wallet-helper`, { currencyId: data.currencyId, posId: data.posId })
		if (response) {
			setWallets(response)
		}
	}

	async function getBanks() {
		const response = await GET(`/services/web/api/bank-helper`, { currencyId: data.currencyId, posId: data.posId })
		if (response) {
			setBanks(response)
		}
	}

	async function getData() {
		const response = await GET(`/services/web/api/documents-in/${id}?size=1000`)
		if (response) {
			response.productList = response.productList.content;
			for (let i = 0; i < response.productList.length; i++) {
				response.productList[i].name = response.productList[i].productName;
			}
			setData(response)
		}
	}

	useEffect(() => {
		if (id) {
			getData()
		}
		getPoses()
		getCategories()
		if (reduxSettings.categoryId) {
			setValue('categoryId', reduxSettings.categoryId)
		}
		if (!reduxTransferData?.documentInCreateProduct) {
			dispatch({
				type: 'SET_DOCUMENT_IN_CREATE_PRODUCT', payload: {
					'gtin': false,
					'seasonal': false,
					'modUomId': false,
					'modQuantity': false,
					'complete': false,
				}
			})
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps	

	useEffect(() => {
		if (reduxTransferData?.documentInCreateData?.productList?.length > 0 && data.productList.length === 0) {
			return;
		}
		if (id) {
			return;
		}
		dispatch({ type: 'SET_DOCUMENT_IN_CREATE_DATA', payload: data })
	}, [data]) // eslint-disable-line react-hooks/exhaustive-deps	

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: '#999',
				fontWeight: 'bold',
				backgroundColor: '#fff',
				fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	function confirmIsDisabled() {
		if ((Number(data.paidAmount) > totalAmount.totalIncome)) {
			return true;
		}
		// if (data.paymentTypeId === 1 && !data.bankId) {
		// 	return true;
		// }
		// if (data.paymentTypeId === 2 && !data.walletId) {
		// 	return true;
		// }
		// if (!(Number(data.paidAmount) >= 0)) {
		// 	return true;
		// }
		return false;
	}

	return (
		<>
			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body position-relative">
						<div className="d-flex justify-content-end">
							<DropdownButton title={t('ACTION')} onSelect={saveToExcel}>
								<Dropdown.Item as="li" eventKey="1">
									<a href="/importDocument.xlsx" className="upload-from-excel">
										{t('DOWNLOAD_TEMPLATE')}
									</a>
								</Dropdown.Item>
								<Dropdown.Item as="li" eventKey="2">
									<input id="actual-btn" hidden type="file" onChange={importFromExel} accept=".csv, .xlsx, .xls, application/vnd.ms-excel" />
									<label htmlFor="actual-btn" className="upload-from-excel">{t('IMPORT_FROM_FILE')}</label>
								</Dropdown.Item>
								<Dropdown.Item as="li" eventKey="3">
									<div className="cursor">{t('SAVE')}</div>
								</Dropdown.Item>
							</DropdownButton>
						</div>

						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('POS')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data.productList.length > 0 || searchData.length > 0}
										options={poses}
										value={poses.find(option => option.id === data.posId)}
										onChange={(option) => setData({ ...data, 'posId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('SUPPLIER')}<span className="required-mark">*</span></label>
									<Select
										options={organizations}
										value={organizations.find(option => option.id === data.organizationId)}
										onChange={(option) => handleSelectOrganization(option)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							{/* <div className="col-md-2">
								<div className="form-group">
									<label>{t('DEBT_PAYMENT_DATE')}</label>
									<input type="number" className="form-control"
										value={data.organizationDate}
										onChange={(e) => setData({ ...data, 'organizationDate': e.target.value })} />
								</div>
							</div> */}
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('CURRENCY')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data.productList.length > 0 || searchData.length > 0}
										options={currencies}
										value={currencies.find(option => option.id === data.currencyId)}
										onChange={(option) => setData({ ...data, 'currencyId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('EXPENSES')}</label>
									<div className="mb-3 input-group">
										<input type="number" className="form-control"
											onChange={(e) => setData({ ...data, expense: e.target.value })} value={data.expense} />
										<span className="input-group-text cursor" onClick={(e) => onChangePrice()}>
											<i className="uil uil-check-circle"></i>
										</span>
									</div>
								</div>
							</div>
							{/* <div className="col-md-2">
								<div className="form-group">
									<label>{t('PAID')}<span className="required-mark">*</span></label>
									<Select
										options={paids}
										value={paids.find(option => option.id === data.paid)}
										onChange={(option) => setData({ ...data, 'paid': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div> */}
							{/* <div className="col-md-2">
								<div className="form-group">
									<label>{t('PAYMENT_METHOD')}<span className="required-mark">*</span></label>
									<Select
										options={paymentTypes}
										value={paymentTypes.find(option => option.id === data.paymentTypeId)}
										onChange={(option) => setData({ ...data, 'paymentTypeId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div> */}
							<div className="col-md-2">
								<div className="form-group">
									<label>№ {t('OVERHEAD')}</label>
									<input type="text" className="form-control" onChange={(e) => setData({ ...data, 'inNumber': e.target.value })} />
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('NOTE')}</label>
									<input className="form-control" onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
							{(reduxSettings.documentsInCreate?.wholesalePrice || !reduxSettings.documentsInCreate) &&
								<div className="col-md-2">
									<div className="form-group">
										<label>{t('MARKUP_WHOLESALEPRICE')}</label>
										<div className="mb-3 input-group">
											<input type="number" className="form-control"
												onChange={(e) => setData({ ...data, 'wholesalePriceMarkup': e.target.value })} />
											<span className="input-group-text">%</span>
										</div>
									</div>
								</div>
							}
							{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.bankPrice) &&
								<div className="col-md-2">
									<div className="form-group">
										<label>{t('MARKUP_BANKPRICE')}</label>
										<div className="mb-3 input-group">
											<input type="number" className="form-control"
												onChange={(e) => setData({ ...data, 'bankPriceMarkup': e.target.value })} />
											<span className="input-group-text">%</span>
										</div>
									</div>
								</div>
							}
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('MARKUP_SALE')}</label>
									<div className="mb-3 input-group">
										<input type="number" className="form-control"
											onChange={(e) => setData({ ...data, 'salePriceMarkup': e.target.value })} />
										<span className="input-group-text">%</span>
									</div>
								</div>
							</div>
							{(reduxSettings.documentsInCreate?.vat || !reduxSettings.documentsInCreate) &&
								<div className="col-md-2">
									<div className="form-group">
										<label>{t('VAT')}</label>
										<input type="number" className="form-control"
											onChange={(e) => setData({ ...data, 'defaultVat': e.target.value })} />
									</div>
								</div>
							}
						</div>
						<div className="printer-icon">
							{data.productList.length > 0 &&
								<div className="d-flex align-items-center gap-2">
									<button type="button" className="table-action-button table-action-danger-button"
										title={t('DELETE_ALL')}
										onClick={() => setModalDeleteAllConfirm(true)}>
										<i className="uil-trash-alt"></i>
									</button>
									{printProducts.length > 0 &&
										<button type="button" className="table-action-button table-action-primary-button" onClick={(e) => {
											e.preventDefault()
											openPriceTagModal()
										}}>
											<i className="uil-print"></i>
										</button>
									}
								</div>
							}
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive mb-3">
							<table className="table table-striped mb-0">
								<thead>
									<tr>
										<th className="text-center">
											<Form.Check // prettier-ignore
												type="checkbox"
												value={allSelected}
												checked={allSelected}
												onChange={() => handleAllSelected()}
											/>
										</th>
										<th>{t('NAME_OF_PRODUCT')}</th>
										<th className="text-center">{t('BARCODE')}</th>
										{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.artikul) &&
											<th className="text-center">{t('ARTIKUL')}</th>
										}
										<th className="text-center">{t('RESIDUE')}</th>
										<th className="text-center">{t('QUANTITY')}</th>
										<th className="text-center">{t('UNIT')}</th>
										<th className="text-center">{t('ADMISSION_PRICE')}</th>
										{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.wholesalePrice) &&
											<th className="text-center">{t('WHOLESALE_PRICE')}</th>
										}
										{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.bankPrice) &&
											<th className="text-center">{t('BANK_PRICE')}</th>
										}
										<th className="text-center">{t('UNIT_PRICE')}</th>
										{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.serial) &&
											<th className="text-center">{t('SERIAL')}</th>
										}
										{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.expDate) &&
											<th className="text-center">{t('EXPIRATION_DATE')}</th>
										}
										{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.vat) &&
											<th className="text-center">{t('VAT')}</th>
										}
										{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.marking) &&
											<th className="text-center">{t('MARKING')}</th>
										}
										<th className="text-end">{t('AMOUNT')}</th>
										<th className="text-center table-control">
											<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										</th>
									</tr>
								</thead>
								<tbody>
									{data.productList.map((item, index) => (
										<tr key={index} className="vertical-middle">
											<td className="text-center">
												<Form.Check // prettier-ignore
													type="checkbox"
													value={item.checked}
													checked={item.checked}
													onChange={() => handleChange(index)}
												/>
											</td>
											<td>{index + 1}. {item.name}</td>
											<td className="text-center">{item.barcode}</td>
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.artikul) &&
												<td className="text-center">{item.artikul}</td>
											}
											<td className="text-center">{formatMoney(item.balance)}</td>
											<td className="text-center">
												<input type="text" className={"auto-width-input " + (!item.quantity && "required-border")}
													disabled={item.serialNumbers?.length > 0}
													name="quantity" value={item.quantity}
													id={index + 1}
													tabIndex={index * 10 + 3}
													onChange={(e) => handleProductListChange(e, index)} />
											</td>
											<td className="text-center">{item.uomName}</td>
											<td className="text-center">
												<input type="number" className={"auto-width-input " + ((Number(item.price) > 1000000000) && "required-border second")}
													name="price" value={item.price}
													tabIndex={index * 10 + 4}
													disabled={reduxSettings.documentsInCalculationByAmount}
													onChange={(e) => handleProductListChange(e, index)} />
											</td>
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.wholesalePrice) &&
												<td className="text-center">
													<input type="text" className={"auto-width-input " + (((Number(item.price) > Number(item.wholesalePrice)) || (Number(item.wholesalePrice) > 1000000000)) && "required-border second")}
														name="wholesalePrice" value={item.wholesalePrice}
														tabIndex={index * 10 + 5}
														onChange={(e) => handleProductListChange(e, index)} />
												</td>
											}
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.bankPrice) &&
												<td className="text-center">
													<input type="text" className={"auto-width-input " + (((Number(item.price) > Number(item.bankPrice)) || (Number(item.bankPrice) > 1000000000)) && "required-border second")}
														name="bankPrice" value={item.bankPrice}
														tabIndex={index * 10 + 5}
														onChange={(e) => handleProductListChange(e, index)} />
												</td>
											}
											<td className="text-center">
												<input type="text" className={"auto-width-input " + (((Number(item.wholesalePrice) > Number(item.salePrice)) || (Number(item.salePrice) > 1000000000)) && "required-border second")}
													name="salePrice" value={item.salePrice}
													tabIndex={index * 10 + 6}
													onChange={(e) => handleProductListChange(e, index)}
												/>
											</td>
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.serial) &&
												<td className="text-center">
													{item?.serialNumbers?.length === 0 ?
														<span className="bg-danger bg-border text-white text-nowrap cursor"
															onClick={() => serialProductsModal(item)}>
															{t('NOT_SPECIFIED')}
														</span>
														:
														<span className="bg-primary bg-border text-white text-nowrap cursor"
															onClick={() => serialProductsModal(item)}>
															{item?.serialNumbers?.length === 1 ?
																<>{item?.serialNumbers[0]}</>
																:
																<>{t('SPECIFIED')}</>
															}
														</span>
													}
												</td>
											}
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.expDate) &&
												<td className="text-center">
													<DatePicker
														tabIndex="-1"
														className="auto-width-input"
														dateFormat="dd.MM.yyyy"
														selected={item.expDate ? new Date(item.expDate) : ''}
														onChange={(date) => handleExpDateChange(date, index)} />
												</td>
											}
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.vat) &&
												<td className="text-center">
													<input type="number" min="0" className={"auto-width-input " + (item.vat === '' && "required-border")}
														name="vat" value={item.vat} tabIndex={index * 10 + 7}
														onChange={(e) => handleProductListChange(e, index)} />
												</td>
											}
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.marking) &&
												<td className="text-center">
													{item.marking ?
														<span className="bg-primary bg-border text-white">{t('YES')}</span>
														:
														<span className="bg-danger bg-border text-white">{t('NO')}</span>
													}
												</td>
											}
											<td className="text-end">
												{reduxSettings.documentsInCalculationByAmount ?
													<input type="number" min="0" className={"auto-width-input " + (!item.totalPrice && "required-border")}
														name="totalPrice" value={item.totalPrice} tabIndex={index * 10 + 7}
														onChange={(e) => handleProductListChange(e, index)} />
													:
													formatMoney(item.quantity * item.price)
												}
											</td>
											<td>
												<div className="d-flex justify-content-center gap-2">
													<div className="table-action-button table-action-primary-button"
														onClick={() => showProductUpdateModal(true, index)}>
														<i className="uil uil-edit"></i>
													</div>
													<div className="table-action-button table-action-danger-button" onClick={() => deleteProduct(index)}>
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="row">
							<div className="col-md-6 vertical-center">
								<div className="form-group position-relative m-0">
									<DebounceInput
										inputRef={searchRef}
										disabled={!data.posId}
										tabIndex={data.productList.length * 10 + 7}
										//tabIndex={1}
										value={search}
										onChange={(e) => searchProduct(e.target.value)}
										onKeyUp={(e) => {
											if (e.keyCode === 13) {
												e.preventDefault()
												searchProduct(e.target.value)
											}
										}}
										type="text"
										className="form-control"
										placeholder={t('SEARCH') + '...'}
										debounceTimeout={1000}
									/>
									{(!reduxSettings?.ofd && canCreateProduct) &&
										<div className="table-action-button table-action-primary-button input-plus-icon me-2" onClick={() => showProductCreateModal(true)}>
											<i className="uil uil-plus"></i>
										</div>
									}
									{searchData.length > 0 ?
										<i className="uil uil-times search-barcode" onClick={() => { setSearchData([]); setSearch('') }}></i>
										:
										<i className={'uil uil-qrcode-scan search-barcode ' + (barcode && 'text-primary')} onClick={() => setBarcode(!barcode)}></i>
									}

									{searchData.length > 0 &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list">
												<span className="dropdown-menu-result">
													<div className="table-responsive p-3">
														<table className="table-striped table">
															<thead>
																<tr>
																	<th>{t('NAME_OF_PRODUCT')}</th>
																	<th className="text-center">{t('BARCODE')}</th>
																	<th className="text-center">{t('QUANTITY')}</th>
																	<th className="text-center">{t('ACTION')}</th>
																</tr>
															</thead>
															<tbody>
																{searchData.map((item, index) => (
																	<tr key={index}>
																		<td>{item.name}</td>
																		<td className="text-center">{item.barcode}</td>
																		<td className="text-center">{item.balance} {item.uomName}</td>
																		{item.productShow ?
																			<td onClick={() => addToList(index)}>
																				<div className="table-actions">
																					<div className="table-action-button table-action-primary-button">
																						<i className="uil uil-plus"></i>
																					</div>
																				</div>
																			</td>
																			:
																			<div className="text-center text-danger">{t('CHOOSE_ANOTHER_CURRENCY')}</div>
																		}
																	</tr>
																))
																}
															</tbody>
														</table>

														<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
													</div>
												</span>
											</div>
										</span>
									}
									{(searchData.length === 0 && searchDataExist === 1) &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list d-flex justify-content-between p-2">
												<span className="dropdown-menu-result fz20">{t('PRODUCT_NOT_FOUND')}</span>
											</div>
										</span>
									}
									{(searchData.length >= 0 && searchDataExist === 2) &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list d-flex justify-content-between p-2">
												<span className="dropdown-menu-result fz20">{t('PRODUCT_ADDED')}</span>
											</div>
										</span>
									}

								</div>
							</div>
							<div className="col-md-6 d-flex justify-content-end">
								<div className="flex-column">
									<h5 className="d-flex justify-content-between mb-1"><strong className="me-2">{t('TOTAL_QUANTITY')}:</strong>{formatMoney(totalAmount.totalQuantity)}</h5>
									<h5 className="d-flex justify-content-between mb-1"><strong className="me-2">{t('RECEIPT_AMOUNT')}:</strong>{formatMoney(totalAmount.totalIncome)} {data.currencyName}</h5>
									<h5 className="d-flex justify-content-between"><strong className="me-2">{t('SALE_AMOUNT')}:</strong>{formatMoney(totalAmount.totalSale)} {data.currencyName}</h5>
								</div>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-outline-danger btn-wide me-2" to="/documents-in" tabIndex="-1">
								{t('CANCEL')}
							</Link>
							<button type="button" className="btn btn-outline-primary btn-wide btn-wide" tabIndex="-1"
								onClick={() => showConfirmModal()}
								disabled={!data.productList.length > 0}>
								{t('SAVE')}
							</button>
						</div>
					</div>
				</div>
			</form>

			{/* MODAL SERIAL */}
			<Modal show={modalSerialNumbers} animation={false} centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalSerialNumbers(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('REGISTER_SERIAL_PRODUCT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form autoComplete="false">
						<div className="mb-2">
							<strong>{t('NAME_OF_PRODUCT')}</strong>: {modalData.name} <br />
							<strong>{t('QUANTITY')}</strong>: {modalData.quantity} <br />
							<strong>{t('UNIT')}</strong>: {modalData.uomName} <br />
						</div>
						<div className="d-flex justify-content-between mb-2">
							<input type="text" className="form-control me-2" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} autoFocus />
							<button className="btn btn-primary" onClick={() => addSerialNumber()} disabled={!serialNumber}>{t('ADD')}</button>
						</div>
						<table className="table-striped table">
							<thead>
								<tr>
									<th>№</th>
									<th>{t('SERIAL')}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{modalData?.serialNumbers?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{item}</td>
										<td>
											<div className="d-flex justify-center">
												<div className="table-action-button table-action-danger-button mr-4" onClick={() => deleteSerialNumber(index)}>
													<i className="uil uil-trash-alt"></i>
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2" onClick={() => setModalSerialNumbers(false)}>{t('CANCEL')}</button>
							<button type="button" className="btn btn-outline-primary w-100" onClick={() => saveItemSerial()}>{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL SERIAL */}

			{/* MODAL SETTINGS */}
			<Modal show={modalProductData} animation={false} size="sm" centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => closeProductDataModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('SETTINGS')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{reduxTransferData?.documentInCreateProduct &&
						<div>
							<div className="d-flex justify-content-between mb-2">
								<div className="vertical-center">
									{t('COMPLETED')}%
								</div>
								<div className="vertical-center">
									<input type="checkbox" name="complete"
										onChange={(e) => handleChangeProductData(e)}
										checked={reduxTransferData['documentInCreateProduct']['complete']} />
								</div>
							</div>
							<div className="d-flex justify-content-between mb-2">
								<div className="vertical-center">
									ИКПУ
								</div>
								<div className="vertical-center">
									<input type="checkbox" name="gtin"
										onChange={(e) => handleChangeProductData(e)}
										checked={reduxTransferData['documentInCreateProduct']['gtin']} />
								</div>
							</div>
							<div className="d-flex justify-content-between mb-2">
								<div className="vertical-center">
									{t('ADDITIONAL_QUANTITY')}
								</div>
								<div className="vertical-center">
									<input type="checkbox" name="modQuantity"
										onChange={(e) => handleChangeProductData(e)}
										checked={reduxTransferData['documentInCreateProduct']['modQuantity']} />
								</div>
							</div>
							<div className="d-flex justify-content-between mb-2">
								<div className="vertical-center">
									{t('ADDITIONAL_UNIT_OF_MEASUREMENT')}
								</div>
								<div className="vertical-center">
									<input type="checkbox" name="modUomId"
										onChange={(e) => handleChangeProductData(e)}
										checked={reduxTransferData['documentInCreateProduct']['modUomId']} />
								</div>
							</div>
							<div className="d-flex justify-content-between mb-2">
								<div className="vertical-center">
									{t('SEASONALITY')}
								</div>
								<div className="vertical-center">
									<input type="checkbox" name="seasonal"
										onChange={(e) => handleChangeProductData(e)}
										checked={reduxTransferData['documentInCreateProduct']['seasonal']} />
								</div>
							</div>
							<div className="d-flex justify-content-between mb-2">
								<div className="vertical-center">
									{t('ARTIKUL')}
								</div>
								<div className="vertical-center">
									<input type="checkbox" name="artikul"
										onChange={(e) => handleChangeProductData(e)}
										checked={reduxTransferData['documentInCreateProduct']['artikul']} />
								</div>
							</div>
						</div>
					}
				</Modal.Body>
			</Modal>
			{/* MODAL SETTINGS */}

			{/* MODAL INSTANT CREATE PRODUCT */}
			<Modal show={modalProduct} animation={false} centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => showProductCreateModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex justify-content-between align-items-center w-100">
						<Modal.Title>{t('NEW_PRODUCT')}</Modal.Title>
						<i className="uil-setting cursor me-3 fz-20" onClick={() => showProductDataModal()}></i>
					</div>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createProduct)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME_OF_PRODUCT')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register('name', { required: true })} autoFocus />
							<span className="text-danger text-sm">{errors.name?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('BARCODE')}<span className="required-mark">*</span></label>
							<div className="position-relative">
								<input type="number" className="form-control" name="barcode" {...register('barcode', { required: true })}
									onKeyUp={(e) => {
										if (e.key === 'ArrowUp') {
											generateRandomBarcode()
										}
									}}
								/>
								<span className="text-danger text-sm">{errors.barcode?.type === 'required' && t('required')}</span>
								<i className="uil-sync input-right-icon"
									style={{ right: '30px' }}
									onClick={() => generateRandomBarcode()}></i>
								<i className="uil uil-shield-check input-right-icon" onClick={searchOfdProduct}></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('CATEGORY')}</label>
							<Controller
								name="categoryId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										components={{ GroupHeading: GroupHead, Option, }}
										classNamePrefix="react-select"
										options={categories}
										value={categoriesInitial.find(option => option.id === getValues('categoryId'))}
										onChange={(e) => setValue('categoryId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.label}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.categoryId?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('UNIT_OF_MEASUREMENT')}</label>
							<Controller
								name="uomId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={uoms}
										value={uoms.find(option => option.id === getValues('uomId')) || ''}
										onChange={(e) => setValue('uomId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.categoryId?.type === 'required' && t('required')}</span>
						</div>
						{reduxTransferData?.documentInCreateProduct?.complete &&
							<div className="form-group">
								<label>{t("COMPLETED")}%</label>
								<input type="number" className="form-control" name="complete" {...register('complete')} />
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.gtin &&
							<div className="form-group">
								<label>ИКПУ</label>
								<input type="number" className="form-control" name="gtin" {...register('gtin')} />
								<span className="text-danger text-sm">{errors.gtin?.message}</span>
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.modQuantity &&
							<div className="form-group">
								<label>{t('ADDITIONAL_QUANTITY')}</label>
								<input type="number" step="0.0001" className="form-control" name="modQuantity" {...register('modQuantity')} />
								<span className="text-danger text-sm">{errors.modQuantity?.message}</span>
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.modUomId &&
							<div className="form-group">
								<label>{t('ADDITIONAL_UNIT_OF_MEASUREMENT')}</label>
								<Controller
									name="modUomId"
									control={control}
									render={({ field }) =>
										<Select
											//isDisabled={id}
											tabIndex={3}
											{...field}
											options={uoms}
											value={uoms.find(option => option.id === getValues('modUomId'))}
											onChange={(e) => setValue('modUomId', e.id)}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>}
								/>
								<span className="text-danger text-sm">{errors.modUomId?.message}</span>
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.seasonal &&
							<div className="form-group">
								<label>{t('SEASONALITY')}</label>
								<Controller
									name="seasonal"
									control={control}
									render={({ field }) =>
										<Select
											//isDisabled={id}
											tabIndex={3}
											{...field}
											options={seasons}
											value={seasons.find(option => option.id === getValues('seasonal'))}
											onChange={(e) => setValue('seasonal', e.id)}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>}
								/>
								<span className="text-danger text-sm">{errors.seasonal?.message}</span>
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.artikul &&
							<div className="form-group">
								<label>{t('ARTIKUL')}</label>
								<input type="text" className="form-control" name="artikul" {...register('artikul')} />
								<span className="text-danger text-sm">{errors.artikul?.message}</span>
							</div>
						}
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2"
								onClick={() => showProductCreateModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL INSTANT CREATE PRODUCT */}

			{/* MODAL INSTANT UPDATE PRODUCT */}
			<Modal show={modalUpdateProduct} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => showProductUpdateModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PRODUCT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={changeProduct} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME_OF_PRODUCT')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" autoFocus
								value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('CATEGORY')}</label>
							<Select
								components={{ Option }}
								classNamePrefix="react-select"
								options={categories}
								value={categoriesInitial.find(option => option.id === selectedItem.categoryId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'categoryId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t("COMPLETED")}%</label>
							<input type="number" className="form-control" name="complete"
								value={selectedItem.complete}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'complete': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t("ARTIKUL")}</label>
							<input type="text" className="form-control" name="artikul"
								value={selectedItem.artikul}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'artikul': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2"
								onClick={() => showProductUpdateModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL INSTANT UPDATE PRODUCT */}

			{/* MODAL INSTANT CREATE SUPPLIER */}
			<Modal show={modalOrganization} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showOrganizationCreateModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('SUPPLIER')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit2(createOrganization)} autoComplete="off">
						<div className="form-group">
							<label>{t('TITLE')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register2('name', { required: true })} autoFocus />
							<span className="text-danger text-sm">{errors2.name?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('PHONE')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone" {...register2('phone', { required: true, maxLength: 9, minLength: 9 })} />
							</div>
							<span className="text-danger text-sm">{errors2.phone?.type === 'required' && t('required')}</span>
							<span className="text-danger text-sm">{errors2.phone?.type === 'minLength' && t('min', { length: 9 })}</span>
							<span className="text-danger text-sm">{errors2.phone?.type === 'maxLength' && t('max', { length: 9 })}</span>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2"
								onClick={() => showOrganizationCreateModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL INSTANT CREATE SUPPLIER */}

			{/* MODAL CONFIRM */}
			<Modal show={modalConfirm} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalConfirm(false)} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{t('CONFIRM')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-2">
						<b>{t('POS')}</b>
						<div className="fw-700">{findFromArrayById(poses, data.posId)}</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<b>{t('SUPPLIER')}</b>
						<div className="fw-700">{findFromArrayById(organizations, data.organizationId)}</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<b>{t('CURRENCY')}</b>
						<div className="fw-700">{findFromArrayById(currencies, data.currencyId)}</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<b className="me-2">{t('RECEIPT_AMOUNT')}:</b>
						<div className="fw-700">{formatMoney(totalAmount.totalIncome)} {data.currencyName}</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<b className="me-2">{t('SALE_AMOUNT')}:</b>
						<div className="fw-700">{formatMoney(totalAmount.totalSale)} {data.currencyName}</div>
					</div>

					<div className="mb-2">
						<h5 className="fw-700 mb-1">{t('EXPENSE_SAFE')}:</h5>
						<Select
							options={wallets}
							value={wallets.find(option => option.walletId === data.expenseWalletId)}
							onChange={(option) => setData({ ...data, 'expenseWalletId': option?.walletId })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => `${option.walletName} - ${formatMoney(option.walletBalance)} ${option.currencyName}`}
							getOptionValue={(option) => option.walletId}
							isClearable
						/>
					</div>
					{data.expenseWalletId > 0 &&
						<div className="row">
							<div className="form-group col-md-6">
								<div className="fw-700">{t('EXPENSE_AMOUNT')}</div>
								<input type="text" className="form-control" value={data.expenseAmount ? formatMoney(data.expenseAmount, 0) : ''}
									onChange={(e) => setData({ ...data, expenseAmount: e.target.value.replace(/[^0-9.]/g, '') })} />
							</div>

							<div className="form-group col-md-6">
								<div className="fw-700">{t('NOTE')}</div>
								<input type="text" className="form-control" value={data.expenseNote}
									onChange={(e) => setData({ ...data, expenseNote: e.target.value })} />
							</div>
						</div>
					}

					<div className="mb-2">
						<h5 className="fw-700 mb-1">{t('CHOOSE_PAYMENT_TYPE')}:</h5>
						<Select
							options={paymentTypes}
							value={paymentTypes.find(option => option.id === data.paymentTypeId) || ''}
							onChange={(option) => {
								var dataCopy = { ...data }
								dataCopy.paymentTypeId = option?.id
								if (dataCopy.paymentTypeId === 1) {
									dataCopy.walletId = wallets[0]['walletId']
									dataCopy.bankId = ""
								} else {
									dataCopy.bankId = banks[0]['bankId']
									dataCopy.walletId = ""
								}
								setData(dataCopy)
							}}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
					{data.paymentTypeId === 1 &&
						<div className="mb-2">
							<div className="fw-700 mb-1">{t('SAFE')}:</div>
							<Select
								options={wallets}
								value={wallets.find(option => option.walletId === data.walletId)}
								onChange={(option) => setData({ ...data, 'walletId': option?.walletId })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => `${option.walletName} - ${formatMoney(option.walletBalance)} ${option.currencyName}`}
								getOptionValue={(option) => option.walletId}
							/>
						</div>
					}
					{data.paymentTypeId === 2 &&
						<div className="mb-2">
							<div className="fw-700 mb-1">{t('BANK')}:</div>
							<Select
								options={banks}
								value={banks.find(option => option.bankId === data.bankId)}
								onChange={(option) => setData({ ...data, 'bankId': option?.bankId })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => `${option.bankName} - ${option.bankBalance} ${option.currencyName}`}
								getOptionValue={(option) => option.bankId}
							/>
						</div>
					}
					{data.paymentTypeId &&
						<div className="row">
							<div className="form-group col-md-6">
								<div className="fw-700">{t('payment_amount_to_supplier')}</div>
								<div className="position-relative">
									<input type="text" className="form-control" value={data.paidAmount ? formatMoney(data.paidAmount, 0) : ''}
										onChange={(e) => setData({ ...data, paidAmount: e.target.value.replace(/[^0-9.]/g, '') })} />
									<i className="input-inner-icon uil uil-transaction" onClick={() => setData({ ...data, paidAmount: totalAmount.totalIncome })}></i>
								</div>
							</div>

							<div className="form-group col-md-6">
								<div className="fw-700">{t('debt_repayment_date')}</div>
								<div className="position-relative">
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={data.debtPaymentDate ? new Date(data.debtPaymentDate) : ''}
											onChange={(date) => setData({ ...data, 'debtPaymentDate': formatDateBackend(date) })}
											disabled={Number(totalAmount.totalIncome) === Number(data.paidAmount)}
											minDate={returnMinDate(new Date(), 0)}
										/>
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
							</div>
						</div>
					}
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex w-100">
						<button className="btn btn-outline-danger w-100 me-2" onClick={() => setModalConfirm(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-outline-success w-100 me-2" disabled={confirmIsDisabled()} onClick={(e) => createUpdate(e, true)}>
							{t('SAVE_TO_DRAFT')}
						</button>
						<button className="btn btn-outline-primary w-100" disabled={confirmIsDisabled()} onClick={(e) => createUpdate(e)}>
							{t('CONFIRM')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL CONFIRM */}

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableDocumentsInCreate'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}

			{/* MODAL PRICE TAG */}
			<Modal show={modalPriceTag} animation={false} centered dialogClassName="custom-modal-width" backdrop="static"
				onHide={() => setModalPriceTag(false)} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{t('CONFIRM')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="card h-table-price-tags2 position-relative">
						<div className="card-body">
							<div className="table-responsive mb-4" style={{ maxHeight: '500px' }}>
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">
												<Form.Check // prettier-ignore
													type="checkbox"
													value={allSelected}
													checked={allSelected}
													onChange={() => handleAllSelected()}
												/>
											</th>
											<th>{t('NAME_OF_PRODUCT')}</th>
											<th className="text-center">{t('BARCODE')}</th>
											<th className="text-center">{t('RESIDUE')}</th>
											<th className="text-center">{t('QUANTITY')}</th>
											<th className="text-center">{t('UNIT')}</th>
											<th className="text-center">{t('ADMISSION_PRICE')}</th>
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.wholesalePrice) &&
												<th className="text-center">{t('WHOLESALE_PRICE')}</th>
											}
											<th className="text-center">{t('UNIT_PRICE')}</th>
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.serial) &&
												<th className="text-center">{t('SERIAL')}</th>
											}
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.expDate) &&
												<th className="text-center">{t('EXPIRATION_DATE')}</th>
											}
											{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.vat) &&
												<th className="text-center">{t('VAT')}</th>
											}
											<th className="text-center table-control">
												<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
												{t('ACTION')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.productList.map((item, index) => (
											<tr key={index} className="vertical-middle">
												<td className="text-center">
													<Form.Check // prettier-ignore
														type="checkbox" value={item.checked}
														checked={item.checked}
														onChange={() => handleChange(index)}
													/>
												</td>
												<td>{index + 1}. {item.name}</td>
												<td className="text-center">{item.barcode}</td>
												<td className="text-center">{formatMoney(item.balance)}</td>
												<td className="text-center">
													<input type="text" className={"auto-width-input " + (!item.printQuantity && "required-border")}
														disabled={item.serialNumbers?.length > 0}
														name="printQuantity"
														value={item.printQuantity}
														id={index + 1}
														tabIndex={index * 10 + 3}
														onChange={(e) => handleProductListChange(e, index)} />
												</td>
												<td className="text-center">{item.uomName}</td>
												<td className="text-center">
													<input type="number" className="auto-width-input"
														name="price" value={item.price}
														tabIndex={index * 10 + 4}
														onChange={(e) => handleProductListChange(e, index)} />
												</td>
												{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.wholesalePrice) &&
													<td className="text-center">
														<input type="text" className={"auto-width-input " + ((Number(item.price) > Number(item.wholesalePrice)) && "required-border")}
															name="wholesalePrice" value={item.wholesalePrice}
															tabIndex={index * 10 + 5}
															onChange={(e) => handleProductListChange(e, index)} />
													</td>
												}
												<td className="text-center">
													<input type="text" className={"auto-width-input " + ((Number(item.wholesalePrice) > Number(item.salePrice)) && "required-border")}
														name="salePrice" value={item.salePrice}
														tabIndex={index * 10 + 6}
														onChange={(e) => handleProductListChange(e, index)}
													/>
												</td>
												{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.serial) &&
													<td className="text-center">
														{item?.serialNumbers?.length === 0 ?
															<span className="bg-danger bg-border text-white text-nowrap cursor"
																onClick={() => serialProductsModal(item)}>
																{t('NOT_SPECIFIED')}
															</span>
															:
															<span className="bg-primary bg-border text-white text-nowrap cursor"
																onClick={() => serialProductsModal(item)}>
																{item?.serialNumbers?.length === 1 ?
																	<>{item?.serialNumbers[0]}</>
																	:
																	<>{t('SPECIFIED')}</>
																}
															</span>
														}
													</td>
												}
												{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.expDate) &&
													<td className="text-center">
														<DatePicker
															tabIndex="-1"
															className="auto-width-input"
															dateFormat="dd.MM.yyyy"
															selected={item.expDate ? new Date(item.expDate) : ''}
															onChange={(date) => handleExpDateChange(date, index)} />
													</td>
												}
												{(!reduxSettings.tableDocumentsInCreate || reduxSettings.tableDocumentsInCreate?.vat) &&
													<td className="text-center">
														<input type="number" min="0" className={"auto-width-input " + (item.vat === '' && "required-border")}
															name="vat" value={item.vat} tabIndex="-1"
															onChange={(e) => handleProductListChange(e, index)} />
													</td>
												}
												<td>
													<div className="d-flex">
														<div className="table-action-button table-action-danger-button ms-2" onClick={() => deleteProduct(index)}>
															<i className="uil uil-times"></i>
														</div>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<div className="h-table-price-tags2 position-relative">
								<div className="h-table-price-tags3">
									<h6 className="mb-2">{t('price_tag')} (60х40 мм)</h6>
									<PriceTag60x40
										cashbox={cashbox}
										data={printProducts[printProducts.length - 1]}
										selectedPriceTag={selectedPriceTag}
										selectPriceTag={selectPriceTag}
										printProducts={printProducts}
										printRef={printRef}>
									</PriceTag60x40>
									<h6 className="mb-2">{t('price_tag')} (60х30 мм)</h6>
									<PriceTag60x30
										cashbox={cashbox}
										data={printProducts[printProducts.length - 1]}
										selectedPriceTag={selectedPriceTag}
										selectPriceTag={selectPriceTag}
										printProducts={printProducts}
										printRef={printRef}
										activateWholeSalePrice={false}>
									</PriceTag60x30>
									<h6 className="mb-2">{t('price_tag')} (40x30 мм)</h6>
									<PriceTag40x30
										cashbox={cashbox}
										data={printProducts[printProducts.length - 1]}
										selectedPriceTag={selectedPriceTag}
										selectPriceTag={selectPriceTag}
										printProducts={printProducts}
										printRef={printRef}
										activateWholeSalePrice={false}>
									</PriceTag40x30>
									<h6 className="mb-2">{t('price_tag')} (30x20 мм)</h6>
									<PriceTag30x20
										cashbox={cashbox}
										data={printProducts[printProducts.length - 1]}
										selectedPriceTag={selectedPriceTag}
										selectPriceTag={selectPriceTag}
										printProducts={printProducts}
										printRef={printRef}
										activateWholeSalePrice={false}
									>
									</PriceTag30x20>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex w-100">
						<button className="btn btn-outline-danger w-100 me-2" onClick={() => setModalPriceTag(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-outline-primary w-100" onClick={() => prevHandlePrint()}>{t('print')}</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL PRICE TAG */}

			{/* MODAL CONFIRM */}
			<ConfirmModal modalConfirm={modalDeleteAllConfirm} setModalConfirm={setModalDeleteAllConfirm}
				confrim={deleteAllProduct} description={t('ARE_YOU_SURE_YOU_WANT_DELETE_ALL')} />
			{/* MODAL CONFIRM */}
		</>
	)
}

export default CreateUpdate

