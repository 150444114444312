import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import XLSX from 'xlsx';

import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch'
import Pagination from 'pages/parts/Pagination'

import Rightbar from '../cheques/Rightbar'
import { PGET, GET } from 'helpers/api'
import { findFromArrayById, formatDateWithTime, formatMoney } from 'helpers/helpers'
import { useSearchParams } from "react-router-dom";

function Index() {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams();

	const reduxSettings = useSelector(state => state.settings)
	const reduxReport = useSelector(state => state.report)
	const reduxAccount = useSelector(state => state.account)

	const URL = `/services/web/api/cheque-by-clientId-pageList/`

	const [showRightBar, setShowRightBar] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)


	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [filterData, setFilterData] = useState({
		posId: Number(searchParams.get("posId")),
		search: '',
	})

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}

		if (item.chequeId !== 0) {
			if (boolean) {
				const response = await GET("/services/web/api/cheque-byId/" + item.id, {}, { loader: true })
				dataCopy[index]['itemsList'] = response
				dataCopy[index]['showSidebar'] = boolean
				setSelectedItem(response)
				setShowRightBar(boolean)
			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	async function exportToExcel() {
		const response = await GET(`/services/web/api/cheque-by-clientId-excel/${reduxAccount?.posId}/` + reduxReport.reportSelectedClient.clientId)

		var excelHeaders = [[t('POS'), t('CASHBOX'), t('SHIFT_NUMBER'), t('CASHIER'), t('CHECK_NUMBER'), t('CHEQUE_DATE'),
		t('SYNC_DATE'), t('STATUS'), t('TOTAL')]]
		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i].posName)
			excelItems.push(response[i].cashboxName)
			excelItems.push(response[i].shiftNumber)
			excelItems.push(response[i].cashierName)
			excelItems.push(response[i].chequeNumber)
			excelItems.push(formatDateWithTime(response[i].chequeDate))
			excelItems.push(formatDateWithTime(response[i].createdDate))
			if (response[i].returned === 0) excelItems.push(t('SUCCESS'))
			if (response[i].returned === 1) excelItems.push(t('PARTIAL_RETURN'))
			if (response[i].returned === 2) excelItems.push(t('RETURN'))
			excelItems.push(response[i].totalPrice)
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('CHEQUES') + ".xlsx");
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL + filterData.posId + `/${reduxReport.reportSelectedClient.clientId}`, filterData)
		if (response && response.data) {
			setPageCount(Math.ceil((response?.headers['x-total-count']) / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
		})
		getData()
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL + filterData.posId + `/${reduxReport.reportSelectedClient.clientId}`, { ...filterData, page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper", {}, { loader: true })
		if (response) {
			setPoses(response)
		}

	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getData(true)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-outline-danger me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-outline-primary" onClick={() => getData(true)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<button className="btn btn-outline-primary btn-wide" onClick={exportToExcel}>
							EXCEL
						</button>
					</div>

					{filterDataExist &&
						<div className="filter-block my-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-danger text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-hover cursor mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th className="text-center">{t('CASHBOX')}</th>
									<th className="text-center">{t('SHIFT_NUMBER')}</th>
									<th className="text-center">{t('CASHIER')}</th>
									<th className="text-center">{t('CHECK_NUMBER')}</th>
									<th className="text-center">{t('CHEQUE_DATE')}</th>
									<th className="text-center">{t('SYNC_DATE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('TOTAL')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index} onClick={() => toggleRightbar(true, item, index)}>
										<td>{item.rowNum}. {item.posName}</td>
										<td className="text-center">{item.cashboxName}</td>
										<td className="text-center">{item.shiftNumber}</td>
										<td className="text-center">{item.cashierName}</td>
										<td className="text-center">{item.chequeNumber}</td>
										<td className="text-center">{formatDateWithTime(item.chequeDate)}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td>
											<div className="table-actions">
												{item.returned === 0 &&
													<span className="bg-success bg-border text-white">{t('SUCCESS')}</span>
												}
												{item.returned === 1 &&
													<span className="bg-warning bg-border text-white text-nowrap">{t('PARTIAL_RETURN')}</span>
												}
												{item.returned === 2 &&
													<span className="bg-danger bg-border text-white">{t('RETURN')}</span>
												}
											</div>
										</td>
										<td className="text-center text-nowrap">{formatMoney(item.totalPrice)} {item.currencyName}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<Rightbar
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				selectedItem={selectedItem}>
			</Rightbar>
		</>
	)
}

export default Index
